import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React, { FunctionComponent, useState } from 'react';
import {
  TextField,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  CardActions,
  IconButton,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import * as yup from 'yup';
import { Formik, Form, Field, FormikProps, FieldProps } from 'formik';
import masterCardBrand from '../../assets/svg/logos/icons8-mastercard-50.svg';
import visaBrand from '../../assets/svg/logos/icons8-visa-50.svg';
import americanExpressBrand from '../../assets/svg/logos/icons8-american-express-50.svg';
import discoverBrand from '../../assets/svg/logos/icons8-discover-50.svg';
import EditField, { IEditFieldProps } from '../../components/EditField/EditField';
import { TextFieldProps } from '@material-ui/core/TextField';
import CardNumberField from '../../components/CardNumberField/CardNumberField';
import MonthField from '../../components/MonthField/MonthField';
import './Payment.scss';
import { createPayment, sendDataToRefferer, createAmexPayment } from '../../services/payment';
import { ICreatePaymentDTO } from '../../../server/src/types/index';
import MaskedField from '../../components/MaskedField/MaskedField';
import Select from '../../components/Select/Select';
import statesJson from '../../assets/json/states.json';
import contriesJson from '../../assets/json/contries.json';
import parishJson from '../../assets/json/parish.json';
import canadaJson from '../../assets/json/canada.json';
import costaRicaProvincesJson from '../../assets/json/costaRicaTerritories.json';
import mexicoJson from '../../assets/json/mexico.json';
import { OptionsType } from 'react-select/src/types';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Countdown from '../../components/Countdown/Countdown';
import ProcParamErrorPage from '../../customComponents/ProcParamErrorPage/ProcParamErrorPage';
import bahamasJson from '../../assets/json/Bahamas.json';
import denmarkJson from '../../assets/json/Denmark.json';
import englandJson from '../../assets/json/England.json';
import germanyJson from '../../assets/json/Germany.json';
import greeceJson from '../../assets/json/Greece.json';
import irelandJson from '../../assets/json/Ireland.json';
import netherlandsJson from '../../assets/json/Netherlands.json';
import scotlandJson from '../../assets/json/Scotland.json';
import walesJson from '../../assets/json/Wales.json';
import IframeWrapper from '../Iframe-wrapper/Iframe-wrapper';
import IframeService from '../../services/iframeService';




const Anguilla: OptionsType<IOptionType> = (Object.keys(bahamasJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const bahamas: OptionsType<IOptionType> = (Object.keys(bahamasJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const denmark: OptionsType<IOptionType> = (Object.keys(denmarkJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const england: OptionsType<IOptionType> = (Object.keys(englandJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const germany: OptionsType<IOptionType> = (Object.keys(germanyJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const greece: OptionsType<IOptionType> = (Object.keys(greeceJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const ireland: OptionsType<IOptionType> = (Object.keys(irelandJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const netherlands: OptionsType<IOptionType> = (Object.keys(netherlandsJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const scotland: OptionsType<IOptionType> = (Object.keys(scotlandJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
const wales: OptionsType<IOptionType> = (Object.keys(walesJson) as string[]).map((name: string) => ({
    value: name,
    label: name,
}));
interface IOptionType { label: string; value: string };
const states: OptionsType<IOptionType> = (Object.keys(statesJson) as string[]).map((abbreviation: string) => ({
  value: abbreviation,
  // @ts-ignore
  label: statesJson[abbreviation],
}));

const contries: OptionsType<IOptionType> = (Object.keys(contriesJson) as string[]).map((name: string) => ({
  value: name,
  label: name,
}));

const parish: OptionsType<IOptionType> = (Object.keys(parishJson) as string[]).map((name: string) => ({
  value: name,
  label: name,
}));

const canadaTerritories: OptionsType<IOptionType> = (Object.keys(canadaJson) as string[]).map((abbreviation: string) => ({
  value: abbreviation,
  // @ts-ignore
  label: canadaJson[abbreviation],
}));

const costaRicaProvinces: OptionsType<IOptionType> = (Object.keys(costaRicaProvincesJson) as string[]).map((name: string) => ({
  value: name,
  label: name,
}));

const mexicaTerritories: OptionsType<IOptionType> = (Object.keys(mexicoJson) as string[]).map((abbreviation: string) => ({
  value: abbreviation,
  // @ts-ignore
  label: mexicoJson[abbreviation],
}));

const initialState = {
  email: '',
  country: contries[0],
  phone: ' ',
  address: '',
  city: '',
  state: null,
  zip: '',
  cardNumber: ' ',
  cvv: '',
  expiryDate: ' ',
  nameOnCard: '',
  paymentAmount: 0,
  terms: false,
  transId: '',
}



const phoneMask = [
  '+', '1', '-',
  /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, /\d/, /\d/,
];
const phoneMaskNone = [
    '+',  /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/,
];
const phoneMaskGeneral = [
    '+', '/d', '/d', '/d', '-',
    /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, '-',
    /\d/, /\d/, /\d/, /\d/,
];

const phoneMaskBelize = [
  '+', '5', '0', '1', '-',
  /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, /\d/, /\d/,
];

const phoneMaskCostaRica = [
  '+', '5', '0', '6', '-',
  /\d/, /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, /\d/, /\d/,
];

const phoneMaskMexica = [
  '+', '5', '2', '-',
  /\d/, /\d/, '-',
  /\d/, /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, /\d/, /\d/,
];

const cardNumberFieldMask = [
  /[3-6]/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/,
]

const AmexCardNumberFieldMask = [
  /[3-6]/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/, /\d/, ' ',
  /\d/, /\d/, /\d/,
]

const cardNumberFieldMaskAlt = '6999 9999 9999 9999';
const amexCardNumberFieldMaskAlt = '6999 9999 9999 999';

interface IDynamicFieldOptions {
  label: string;
  phoneMask: any[];
  showStates: boolean;
  states: IOptionType[];
}
function getDynamicFieldsOptions(country: string = 'USA'): IDynamicFieldOptions {
  let options: IDynamicFieldOptions = {
    label: 'State/Territory',
    phoneMask: phoneMaskNone,
    showStates: false,
    // @ts-ignore
    states,
  };
  switch (country) {
      case 'USA': options = {
          ...options,
          label: 'States',
          phoneMask: phoneMask,
          showStates: true,
          // @ts-ignore
      }; break;
      case 'Anguilla': options = {
          ...options,
          phoneMask: phoneMaskBelize,
          showStates: false,
      }; break;
    case 'Belize': options = {
      ...options,
      phoneMask: phoneMaskBelize,
      showStates: false,
    }; break;
    case 'Bermuda': options = {
      ...options,
      label: 'Parish',
      // @ts-ignore
      states: parish,
    }; break;
    case 'Canada': options = {
      ...options,
      label: 'Province',
        showStates: true,
      // @ts-ignore
      states: canadaTerritories,
    }; break;
    case 'Costa Rica': options = {
      ...options,
      label: 'Province',
      phoneMask: phoneMaskCostaRica,
        showStates: true,
        // @ts-ignore
      states: costaRicaProvinces,
    }; break;
    case 'Bahamas': options = {
      // @ts-ignore
      states: bahamas,
    }; break;
      case 'Denmark': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: denmark,
      }; break;
      case 'England': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: england,
      }; break;
      case 'Germany': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: germany,
      }; break;
      case 'Greece': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: greece,
      }; break;
      case 'Ireland': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: ireland,
      }; break;
      case 'Netherlands': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: netherlands,
      }; break;
      case 'Scotland': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: scotland,
      }; break;
      case 'Wales': options = {
          ...options,
          showStates: true,
          // @ts-ignore
          states: wales,
      }; break;
  }
  return options;
}

const requiredValidationMessage = ' is required field';

const Payment: FunctionComponent<{}> = (props: any) => {
  // debugger;
  const [status, setStatus] = useState('progress');
  const [message, setMessage] = useState('');
  const [response, setResponse] = useState({});
  const [isTermsModalOpened, setTermsModalOpened] = useState(false);
  const [amount, setAmount] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const userId = params.get('ou');
  const name = params.get('name');
  const ispp = params.get('ispp');
  const referrer = params.get('referrer');
  const proc = params.get('proc') || '';
  const paymentAmount = params.get('amount');
  const isPaymentAmountSet = !!paymentAmount;
  const packageName = params.get('packagename');
  const isSandbox = !!params.get('sandbox') && (params.get('sandbox') === '1');
  const cardNumber = params.get('cardnumber');

  const validationSchema = yup.object().shape<ICreatePaymentDTO>({
    email: yup.string().email().required(`Email ${requiredValidationMessage}`),
    country: yup.mixed().required(`Country ${requiredValidationMessage}`),
    phone: yup.string().ensure(),
    address: yup.string().required(`Address ${requiredValidationMessage}`),
    city: yup.string().required(`City ${requiredValidationMessage}`),
    state: yup.mixed().test(
      'state',
      `State ${requiredValidationMessage}`,
      function (value: yup.TestContext): boolean {
        const countryValue = this.parent.country;
        const isBelizeCountrySelected = ((countryValue !== null) && (countryValue.value === 'Belize'));
        //return isBelizeCountrySelected || !!value;
          return true;
      }
    ),
    zip: yup.string()
      .typeError(`Zip ${requiredValidationMessage}`)
      .required(`Zip ${requiredValidationMessage}`),
    nameOnCard: yup.string().required(`Name ${requiredValidationMessage}`),
    cardNumber: yup.string().test(
      'nameOnCard',
      'wrong card number',
      (value: yup.TestContext) => {
        return RegExp(/^([0-9 ])*$/g).test(value as unknown as string);
      }
    ).required(`Card number ${requiredValidationMessage}`),
    paymentAmount: yup.string().ensure(), //this needs to make sure it is a decimal
      //.min(1, 'must be more than 0')
      //.max(99999999999999, 'must be less than 100000000000000')
      //.required(`Payment amount ${requiredValidationMessage}`),
    /*terms: yup.bool().test(
      'terms',
      'you need to agree to terms of service',
      (value: any) => {
        return value;
      }
    ),*/
    expiryDate: yup.string().test(
      'expiryDate',
      'wrong date format',
      (value: yup.TestContext) => {
        return RegExp(/^([0-9 /])*$/g).test(value as unknown as string);
      }
    ).required(`Expiry date ${requiredValidationMessage}`),
    cvv: yup.string()
      //.min(3, 'must contain more then 2 digits')
      .typeError(`CVV ${requiredValidationMessage}`)
      .required(`CVV ${requiredValidationMessage}`),
    transId: yup.string()
  });

  function getCvvDigitsCount(cardNumber: string | null): number {
    const [firstDigit] = cardNumber ? cardNumber.split('') : [''];
    let cvvDigitsCount: number;
    switch (firstDigit) {
      case '3': cvvDigitsCount = 4; break;
      case '4': cvvDigitsCount = 3; break;
      case '5': cvvDigitsCount = 3; break;
      case '6': cvvDigitsCount = 3; break;
      default: cvvDigitsCount = 4;
    }
    return cvvDigitsCount;
  }


  function closeMessage() {
    setStatus('progress');
  }

  function handleSendResponse() {
    window.location.replace(
      referrer ?
        `${referrer}/?response=${encodeURIComponent(JSON.stringify(response))}` :
        ''
    );
  }


  async function handleSubmit(values: ICreatePaymentDTO) {
      console.log("Assembling payment details");
    const newValues = Object.keys(values)
      .reduce((modifiedValues: ICreatePaymentDTO, key: string) => {

        const isHaseUnnecesarySigns = (key !== 'nameOnCard') &&
          (key !== 'address') &&
          (key !== 'city') &&
          (key !== 'email') &&
          (typeof values[key] === 'string');

        if (key !== 'paymentAmount') {
            if (isHaseUnnecesarySigns) {
                modifiedValues[key] = values[key].replace(/[^0-9]/g, '');
            }
        }
        console.log(modifiedValues);
        return modifiedValues;

      }, values);

    try {
        console.log("Trying to send payment");
        //const isAmex = false;
        const isAmex = (getCvvDigitsCount(values.cardNumber) === 4) &&
         (proc !== 'b1') && (proc !== 'p1') && (proc !== 'p2') &&
         (proc !== 'b2') && (proc !== 'd') && (proc !== 'D') && 
         (proc !== 'em') && (proc !== 's') && (proc !== 'S2') && 
	(proc !== 't');

       if (isAmex) {
           console.log('Amex Transaction Started');
           //const response = await createAmexPayment(newValues, {
           //userId,
           //name,
           //ispp,
           //referrer,
           //proc,
           //isSandbox,
         //});
        const response = await createPayment(newValues, {
          userId,
          name,
          ispp,
          referrer,
          proc,
          isSandbox,
        });
         setMessage('transaction successfully created');
         console.log('Amex transaction successfully created');
         setMessage(response.data.message);
         setResponse(response.data.data);
         setStatus('success');

       } else {
           console.log('Authorize Transaction Started');
        const response = await createPayment(newValues, {
          userId,
          name,
          ispp,
          referrer,
          proc,
          isSandbox,
        });
        console.log('Auth transaction successfully created');
        setMessage(response.data.message);
        setResponse(response.data.data)
        setStatus('success');

       }
        console.log("Payment Amount");
        console.log(paymentAmount);
        console.log("Sending Success event to iframe");

        //IframeService.sendSuccessEventToIframe(response.data.data);

        setAmount((values.paymentAmount !== null) ? (1 * (values.paymentAmount as any)) : 0);
        console.log("Payment Amount");
        console.log(paymentAmount);

    } catch (e) {
      console.log(e);
      console.log(e.response);
      setMessage(e.response && e.response.data.message);
      setStatus('error');
      IframeService.sendErrorEventToIframe(e.response);
    }
  }



  async function handleToggleTermsModal() {
    setTermsModalOpened(!isTermsModalOpened);
  }

  const isCompleted = status !== 'progress';
  const isError = status === 'error';
  const isSuccess = status === 'success';
  const isProcParamValid = ['s', 'S','S2','s2', 'd', 'D', 'p1', 'P1', 'p2', 'P2', 'b1', 'B1', 'b2', 'B2', 'ip', 'ut', 'M', 't', 'sc', 'sb', 'em', 'EM', 'tr', 'TR', 'z'].indexOf(proc) >= 0;
  console.log(proc, !isProcParamValid);


  return <div className="payment">
    {
      isProcParamValid &&
      <Card>
        <Formik
          initialValues={{
            ...initialState,
            paymentAmount,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props: FormikProps<ICreatePaymentDTO>) => {
            const isAmex = getCvvDigitsCount(props.values.cardNumber) === 4;
            const isCountrySelected = Boolean(props.values.country);
            const dynamikFildsOptions: IDynamicFieldOptions = getDynamicFieldsOptions(
              (props.values.country !== null) ?
                props.values.country.value :
                undefined,
            );
            function handleTermsClick(e: any) {
              const isChecked = !e.target.checked;
              if (!isChecked) {
                setTermsModalOpened(true);
              } else {
                props.setFieldValue('terms', false);
              }
            }
            return <Form>
              <CardContent className="payment-content">
                <CardHeader
                  title="IDI Secure Pay"
                  titleTypographyProps={{
                    align: 'center',
                  }}
                />
                <Typography align="center">
                  <img src={masterCardBrand} alt="master card brand" />
                  <img src={visaBrand} alt="visa brand" />
                  <img src={americanExpressBrand} alt="american express brand" />
                  <img src={discoverBrand} alt="discover brand" />
                </Typography>
                <Field
                  name="paymentAmount"
                  render={(meta: FieldProps<IEditFieldProps>) =>
                    <EditField
                      {...meta.field}
                      variant="outlined"
                      margin="dense"
                      label="Payment Amount"
                      isEditable={!isPaymentAmountSet}
                      suffix={!isPaymentAmountSet ? '' : packageName}
                    />
                  }
                />
                {/*<Field
                name="terms"
                render={(meta: FieldProps<TextFieldProps>) =>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...meta.field}
                        checked={meta.field.value}
                        onChange={() => { }}
                        onClick={handleTermsClick}
                        color="primary"
                      />
                    }
                    label="Agree to terms of service"
                  />
                }
              />*/}
                <Field
                  name="nameOnCard"
                  render={(meta: FieldProps<TextFieldProps>) =>
                    <TextField
                      {...meta.field}
                      label="Name on card"
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      helperText={!!props.errors.nameOnCard && !!props.touched.nameOnCard && props.errors.nameOnCard}
                      error={!!props.errors.nameOnCard && !!props.touched.nameOnCard}
                    />
                  }
                />
                {
                  <Field
                    name="cardNumber"
                    render={(meta: any) =>
                      <CardNumberField
                        {...meta.field}
                        label="Card number"
                        variant="outlined"
                        margin="dense"
                        mask={isAmex ? amexCardNumberFieldMaskAlt : cardNumberFieldMaskAlt}
                        fullWidth
                        helperText={!!props.errors.cardNumber && !!props.touched.cardNumber && props.errors.cardNumber}
                        error={!!props.errors.cardNumber && !!props.touched.cardNumber}
                      />
                    }
                  />
                }
                <div className="payment-group">
                  <Field
                    name="expiryDate"
                    render={(meta: any) =>
                      <MonthField
                        {...meta.field}
                        label="Expiry date"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        helperText={!!props.errors.expiryDate && !!props.touched.expiryDate && props.errors.expiryDate}
                        error={!!props.errors.expiryDate && !!props.touched.expiryDate}
                      />
                    }
                  />
                  <Field
                    name="cvv"
                    render={(meta: any) =>
                      <TextField
                        {...meta.field}
                        label="CVV"
                        variant="outlined"
                        // type="number"
                        margin="dense"
                        fullWidth
                        inputProps={{ min: "0", max: "9999", step: "1", maxLength: "4" }}
                        //InputProps={{ inputProps: { min: 0, max: 4 } }}
                        //inputProps={{ maxLength: getCvvDigitsCount(props.values.cardNumber) }}
                        helperText={!!props.errors.cvv && !!props.touched.cvv && props.errors.cvv}
                        error={!!props.errors.cvv && !!props.touched.cvv}
                      />
                    }
                  />
                </div>
                <Field
                  name="email"
                  render={(meta: FieldProps<TextFieldProps>) =>
                    <TextField
                      {...meta.field}
                      label="Email"
                      type="email"
                      autoComplete="email"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      helperText={!!props.errors.email && !!props.touched.email && props.errors.email}
                      error={!!props.errors.email && !!props.touched.email}
                    />
                  }
                />
                <Field
                  name="country"
                  render={(meta: FieldProps<TextFieldProps>) =>
                    <Select
                      options={contries}
                      label="Country"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      helperText={!!props.errors.country && !!props.touched.country && props.errors.country}
                      error={!!props.errors.country && !!props.touched.country}
                      {...meta.field}
                      onChange={(e: any) => {
                        props.setFieldValue('state', null);
                        meta.field.onChange(e)
                      }}
                    />
                  }
                />
                <Field
                  name="phone"
                  render={(meta: FieldProps<TextFieldProps>) =>
                    <TextField
                      {...meta.field}
                      label="Phone number"
                      margin="dense"
                      variant="outlined"
                      //mask={dynamikFildsOptions.phoneMask}
                      fullWidth
                      //disabled={!isCountrySelected}
                      //helperText={!!props.errors.phone && !!props.touched.phone && props.errors.phone}
                      //error={!!props.errors.phone && !!props.touched.phone}
                    />
                  }
                />
                <Field
                  name="address"
                  render={(meta: FieldProps<TextFieldProps>) =>
                    <TextField
                      {...meta.field}
                      label="Address"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      disabled={!isCountrySelected}
                      helperText={!!props.errors.address && !!props.touched.address && props.errors.address}
                      error={!!props.errors.address && !!props.touched.address}
                    />
                  }
                />
                <Field
                  name="city"
                  render={(meta: FieldProps<TextFieldProps>) =>
                    <TextField
                      {...meta.field}
                      label="City"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      disabled={!isCountrySelected}
                      helperText={!!props.errors.city && !!props.touched.city && props.errors.city}
                      error={!!props.errors.city && !!props.touched.city}
                    />
                  }
                />
                {
                  dynamikFildsOptions.showStates &&
                  <Field
                    name="state"
                    render={(meta: FieldProps<TextFieldProps>) =>
                      <Select
                        options={dynamikFildsOptions.states}
                        label={dynamikFildsOptions.label}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled={!isCountrySelected}
                        helperText={!!props.errors.state && !!props.touched.state && props.errors.state}
                        error={!!props.errors.state && !!props.touched.state}
                        {...meta.field}
                        onChange={(e: any) => {
                          meta.field.onChange(e);
                        }}
                      />
                    }
                  />
                }
                <Field
                  name="zip"
                  render={(meta: FieldProps<TextFieldProps>) =>
                    <TextField
                      {...meta.field}
                      label="Zip"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      disabled={!isCountrySelected}
                      helperText={!!props.errors.zip && !!props.touched.zip && props.errors.zip}
                      error={!!props.errors.zip && !!props.touched.zip}
                    />
                  }
                />
                <Dialog
                  open={isTermsModalOpened}
                  onClose={handleToggleTermsModal}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{"Agree to terms of service?"}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <iframe
                        src="https://app.termly.io/document/terms-of-use-for-ecommerce/30d7c54b-bfcd-4bd1-89f6-bb2a7d49ab92"
                        style={{ border: '0px', margin: '0px', height: '500px', width: '500px' }} name="terms" scrolling="yes" frameBorder="1"
                        allowFullScreen></iframe>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={(e: any) => {
                      handleToggleTermsModal();
                      props.setFieldValue('terms', true);
                    }} color="primary" autoFocus>
                      I Agree
                  </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>


              <CardActions>
                <div className="payment-footer">
                  <IframeWrapper>
                    <Button
                      variant="outlined"
                      type="submit"
                      className="cancel-iframe-btn"
                      onClick={IframeService.cancelIframe}
                      fullWidth>
                        Cancel
                    </Button>
                  </IframeWrapper>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                    // className="payment-submit"
                    disabled={!props.isValid}
                  >
                    Pay $ {props.values.paymentAmount}
                  </Button>
                </div>
              </CardActions>
            </Form>
          }}
        </Formik>
      </Card>
    }
    {
      isCompleted &&
      <div className="payment_message">
        <div className="payment_message-toolbar">
          {
            isError &&
            <IconButton onClick={closeMessage}>
              <HighlightOffIcon
                color="primary"
                fontSize="large"
              />
            </IconButton>
          }
          {
            isSuccess &&
            <IconButton onClick={closeMessage}>
              <HighlightOffIcon
                color="primary"
                fontSize="large"
              />
            </IconButton>
          }
        </div>

        <Typography align="center" component="h1">
          <Typography>
            {
              isError &&
              <>
                <div className="payment_message-icon">
                  <HighlightOffIcon
                    color="error"
                    fontSize="inherit"
                  />
                </div>
                <p>
                  Your payment could not be processed. Please try again..

                </p>

                <Typography color="textSecondary" align="center" component="p">
                  Reason: {message}

                </Typography>
              </>
            }
            {
              isSuccess &&
              <>
                <div className="payment_message-icon">
                  <CheckCircleOutlineIcon
                    color="primary"
                    fontSize="inherit"
                  />
                </div>
                <div className="payment_message-amount">
                  {`$${amount}`}
                </div>
                <p>
                  Your payment is completed.
                </p>
              </>
            }
          </Typography>
        </Typography>
        {
          isSuccess &&
          <>
            <Typography align="center" component="p">
              Yuo will be redirected back to the original site in
              {' '}
              <Countdown
                startFrom={20}
                onCountsEnd={handleSendResponse}
              />
              {' '}
              seconds
            </Typography>
            {/* @todo - add this feature for amex also */}
            <Typography className="payment_message-code" color="textSecondary" align="center" component="p">
              Verification code:
            </Typography>
            <Typography color="textSecondary" align="center" component="p">
              {(response as any).transId}
            </Typography>
          </>
        }
      </div>
    }
    {
      !isProcParamValid &&
      <ProcParamErrorPage />
    }
  </div>
}

export default Payment;
